@mixin animation($setting) {
  -webkit-animation: $setting;
  -moz-animation: $setting;
  -ms-animation: $setting;
  -o-animation: $setting;
  animation: $setting;
}
@mixin animation-duration($setting) {
  -webkit-animation-duration: $setting;
  animation-duration: $setting;
}
@mixin animation-fill-mode($setting) {
  -webkit-animation-fill-mode: $setting;
  animation-fill-mode: $setting;
}
@mixin animation-delay($setting) {
  -webkit-animation-delay: $setting;
  animation-delay: $setting;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem) scaleY(0.1);
  }
  //   50% {
  //     transform: translateX(-2rem) scaleY(0.8);
  //   }
  100% {
    opacity: 1;
    transform: translate(0) scaleY(1);
  }
}

@keyframes moveInDown {
  0% {
    opacity: 0;
    transform: translateY(-7rem) scaleY(0.5);
  }

  100% {
    opacity: 1;
    transform: translate(0) scaleY(1);
  }
}

@keyframes moveFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(2rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveFromRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-2rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveFromDown {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }
  80% {
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes moveOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes tooltip {
  0% {
    opacity: 0;
    transform: translateY(-1rem), scale(0.1);
  }

  100% {
    opacity: 1;
    transform: translateY(0), scale(1);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: none;
  }
  50% {
    transform: scale(1.1);
    // box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.25);
  }
  100% {
    transform: scale(1);
    box-shadow: none;
  }
}

@keyframes enable {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
