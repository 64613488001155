.terms {
  & p {
    font-size: 13px;
    text-align: center;
  }

  ul {
    padding-left: 1rem;
    li {
      list-style: none;
      margin-bottom: 5px;
      & b {
        font-size: 13px;
      }

      & i {
        margin-right: 5px;
        font-weight: 700;
      }
    }
  }
}
