.main {
  &__body {
    & .table-responsive {
      overflow: hidden;
    }
  }

  &__statContainer {
    .stats__list {
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &-item {
        border: 1px solid #333;
        background: #333331;
        min-width: 15rem;
        height: 7rem;
        margin-bottom: 1.5rem;
        box-shadow: 0 1.2rem 3rem rgba(0, 0, 0, 0.3);

        color: #fff;
        border-radius: 3px;
        display: flex;
        align-items: stretch;
        transition: all 0.4s;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
        }

        .item-count {
          width: 7rem;
          font-size: 1rem;
          //   padding: 0 0.5rem;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;

          & p {
            font-weight: 700;
          }

          @include respond(phone) {
            & {
              margin: auto;
            }
          }
        }

        @include respond(tab-land) {
          & {
            width: 40%;
          }
        }
      }

      @include respond(phone) {
        & {
          flex-direction: column;

          &-item {
            width: 100%;
          }
        }
      }

      //   .product {
      //   }

      //   .brand {
      //   }

      //   .category {
      //   }

      //   .sales {
      //   }
      //   .sellers {
      //   }
    }
  }

  .app-overview {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__info {
      & h1 {
        text-align: center;
        font-weight: 700;
        color: #fff;
        background: $shadow;
        padding: 0.5rem;
        border-radius: 32px;
      }
      .app-overview__block {
        width: 50%;
        margin-top: 4rem;
        text-align: right;
        position: relative;

        & > * {
          margin-right: 2.5rem;
        }

        &--1::before {
          content: '1';
          position: absolute;
          background-color: $shadow;
          color: #fff;
          top: 0;
          text-align: center;
          padding: 1rem 1.4rem;
          font-weight: 700;
          border-radius: 50%;
          border: 3px solid #dee2e6;
        }

        &--2 {
          margin-left: auto;
          text-align: left;

          &::before {
            content: '2';
            position: absolute;
            background-color: $shadow;
            color: #fff;
            top: 0;
            text-align: center;
            padding: 1rem 1.4rem;
            font-weight: 700;
            border-radius: 50%;
            border: 3px solid #dee2e6;
          }

          &::after {
            content: '';
            position: absolute;
            width: 0.2rem;
            height: 17rem;
            background-color: $shadow;
            top: -95px;
            left: 28px;
            z-index: -1;

            @media only screen and (max-width: 992px) {
              & {
                height: 22rem;
                top: -114px;
              }
            }

            @media only screen and (max-width: 832px) {
              & {
                height: 24rem;
                top: -132px;
              }
            }

            @include respond(phone) {
              & {
                left: unset;
                right: -30px;
                height: 22rem;
              }
            }
          }

          & > * {
            margin-right: 0;
            margin-left: 5.5rem;
          }

          @include respond(phone) {
            & {
              margin-right: auto;
              text-align: right;
              margin-left: 0;

              & > * {
                margin-right: 2.5rem;
                margin-left: 0;
              }
            }
          }
        }

        &--3::before {
          content: '3';
          position: absolute;
          background-color: $shadow;
          color: #fff;
          top: 0;
          text-align: center;
          padding: 1rem 1.4rem;
          font-weight: 700;
          border-radius: 50%;
          border: 3px solid #dee2e6;
        }

        @include respond(phone) {
          & {
            width: 75%;
          }
        }
      }
    }

    &__login {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 60%;
      margin: 2rem;
      margin-right: -2rem;

      & > * {
        min-width: 40%;
        font-weight: 700 !important;
        font-size: 1rem !important;
        padding: 1rem 1.3rem !important;
        box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
      }

      @include respond(phone) {
        & {
          width: 100%;
          margin-right: 2rem;
        }
      }
    }

    &__presentation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2rem 0;

      & .video_container {
        width: 100%;
        text-align: center;
        border: 1px solid;
        align-self: stretch;
      }

      & .description {
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
      }

      @include respond(tab-land) {
        & {
          flex-direction: column;
        }
      }
    }

    &__screenshots {
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 0.8rem;

      & .image__container {
        width: 100%;
        cursor: pointer;
        overflow: hidden;

        & .image-card {
          width: 100%;
          &:hover {
            transform: scale(1.3);
            transition: all 5s cubic-bezier(0.25, 0.45, 0.45, 0.95);
          }
        }
      }

      #lightbox {
        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.52);
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        justify-content: space-between;

        & button {
          position: absolute;
          background: transparent;
          color: #ccc;
          border: none;
          transition: all 0.4s;

          &:focus,
          &:active {
            outline: none;
          }

          &:hover {
            color: #fff;
          }
        }

        & img {
          width: 100%;
        }

        .lightbox__next {
          right: 16px;
        }
      }

      @include respond(tab-land) {
        & {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
      @include respond(phone) {
        & {
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    &__questions {
      .how-to-use {
        margin: 2rem 0;
        padding: 1rem;
        background-color: $shadow;
        color: #fff;
        text-align: center;

        &__header {
          margin: 2rem 3rem 3rem 3rem;
        }

        &__options {
          display: flex;
          justify-content: space-between;

          & .option {
            flex: 1;

            &.from-left {
              animation: moveFromLeft 1s ease-out 1s backwards;
            }
            &.from-right {
              animation: moveFromRight 1s ease-out 1s backwards;
            }
            &.from-down {
              animation: moveFromDown 1s ease-out 1s backwards;
            }

            & h4 i {
              margin-right: 0.5rem;
            }

            &:not(:last-child) {
              margin-right: 1rem;
            }
          }

          @include respond(phone) {
            & {
              flex-direction: column;
              & .option {
                margin: 2rem 0;

                &.from-right {
                  animation: moveFromLeft 1s ease-out 3s backwards;
                }

                &.from-down {
                  animation: moveFromLeft 1s ease-out 2s backwards;
                }
              }
            }
          }
        }
      }

      .popular-question {
        & .question {
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &.from-right {
            animation: moveFromRight 1s ease-out 1s backwards;
          }
          &.from-right2 {
            animation: moveFromRight 1s ease-out 2s backwards;
          }

          h4 {
            max-width: 30%;
          }

          &__answers {
            margin-left: 3rem;
          }
        }
      }

      .how-match {
        background-color: $shadow;
        color: #fff;
        margin: 2rem 0;
        padding: 2rem;
        text-align: center;

        & h1 {
          margin-bottom: 2rem;
        }
      }

      .tariffs {
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &.from-down {
          animation: moveFromDown 1s ease-out 2s backwards;
        }

        & .tariff {
          max-width: 31%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        @include respond(tab-land) {
          & {
            justify-content: unset;
            flex-direction: column;
            & .tariff {
              width: 100%;
              max-width: 100%;
              margin: 0 auto;
            }
          }
        }
      }
      .discount {
        text-align: center;
        margin: 2rem 0;

        &.moveIn {
          animation: moveIn 1s ease-in 3s;
        }
      }
    }

    &__want-use {
      text-align: center;
      margin: 2rem 0;

      &-header {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 0 auto;
        h3 {
          margin-bottom: 1rem;
        }

        @include respond(phone) {
          & {
            width: 100% !important;
          }
        }
      }

      &-options {
        display: flex;
        justify-content: space-around;
        margin-top: 2rem;

        & .option {
          color: #333;
          border: 1px solid #ccc;
          border-radius: 35px;
          max-width: 45%;
          display: flex;
          align-items: center;
          background: #fff;
          box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
          transform: translateY(0);

          transition: all 0.4s ease-in;

          &:hover {
            transform: translateY(-3px);
            box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.3);
          }
          & > * {
            margin: 2rem;
          }

          &__desc div {
            background-image: linear-gradient(to bottom, #0bc4c4, #019797);
            padding: 1rem;
            color: #fff;
            font-weight: 700;
            border-radius: 9px;
          }
        }
        & & .option:active,
        & .option:focus {
          transform: translateY(1px);
          box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
        }

        @include respond(tab-land) {
          & {
            flex-direction: column;

            & .option {
              width: 100%;
              max-width: 100%;
              margin-bottom: 2rem;
              border-radius: unset;
            }
          }
        }
      }
    }
  }

  & .stock__container {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: '1200px') {
      & {
        flex-direction: row;
      }
    }
  }

  & .intro {
    background: #2d353c;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 3px;
    animation: moveIn 1s ease-out 3.5s backwards;

    &__description {
      padding: 4rem;
      text-align: center;
      animation: moveFromDown 1s ease-out 4.5s backwards;
    }

    @media screen and (max-width: '1200px') {
      & {
        flex-direction: column;
        &__description {
          padding: 2rem;
        }
      }
    }
  }

  & .pulse {
    transition: all 1s;
    // &:hover {
    animation: pulsate 1s infinite;
    // }
  }

  &__video-container {
    max-width: 30rem;
    width: 100%;
    height: 15rem;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 45%;
    border-radius: 4px;
    overflow: hidden;
    animation: moveIn 1s ease-out 5s backwards;
  }

  .topBrands {
    transition: all 0.2s ease-in;
    &--0 {
      animation: moveFromRight 0.5s ease-out 0.4s backwards;
    }
    &--1 {
      animation: moveFromRight 0.5s ease-out 0.8s backwards;
    }
    &--2 {
      animation: moveFromRight 0.5s ease-out 1.2s backwards;
    }
    &--3 {
      animation: moveFromRight 0.5s ease-out 1.6s backwards;
    }
    &--4 {
      animation: moveFromRight 0.5s ease-out 2s backwards;
    }

    &:hover {
      font-weight: 700;
    }
  }

  .topProducts {
    transition: all 0.2s ease-in;

    &--0 {
      animation: moveFromRight 0.5s ease-out 2.4s backwards;
    }
    &--1 {
      animation: moveFromRight 0.5s ease-out 2.8s backwards;
    }
    &--2 {
      animation: moveFromRight 0.5s ease-out 3.2s backwards;
    }
    &--3 {
      animation: moveFromRight 0.5s ease-out 3.6s backwards;
    }
    &--4 {
      animation: moveFromRight 0.5s ease-out 4s backwards;
    }
    &:hover {
      font-weight: 700;
    }
  }

  .stat_image {
    @media screen and (max-width: '1420px') {
      & {
        height: 100px;
      }
    }

    @media screen and (max-width: '1200px') {
      & {
        height: 150px;
      }
    }
  }
}
