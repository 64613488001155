.categoriesList {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.8rem;

  @media only screen and (max-width: 1700px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @include respond(desktop) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include respond(tab-land) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 979px) {
    grid-template-columns: 1fr 1fr;
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
    grid-gap: 0.3rem;
  }

  &__container {
    position: relative;
    transition: all 1s;
    width: 100%;
    height: 28rem;

    @include respond(phone) {
      width: 97vw;
      height: auto;
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__card {
    border: 1px solid #ccc;
    background-color: #333;
    border-radius: 1rem;
    // width: 14rem;
    // height: 28rem;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 1s;

    &-header {
      font-size: 1.5rem;
      color: #fff;
      font-weight: 700;
      & a {
        color: rgba(255, 255, 255, 0.6);
        transition: all 0.2s ease-in;

        &:hover {
          color: #fff;
        }
      }

      // margin-top: auto;
      // margin-bottom: 7rem;
      @include respond(phone) {
        font-size: 2.2rem;
      }
    }

    // &-over {
    //   cursor: pointer;
    //   width: 100%;
    //   flex: 0 0 20%;
    //   color: rgba(255, 255, 255, 0.6);
    //   // transform: rotateX(50deg);
    //   transition: all 0.4s ease;

    //   &:hover {
    //     color: #fff;
    //     transform: scaleX(1.2);
    //   }
    // }

    .sub__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      & a {
        position: relative;
      }

      .sub {
        font-size: 0.7rem;
        color: rgba(255, 255, 255, 0.6);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &__tooltip {
          display: none;
          position: absolute;
          background: #fff;
          right: 2.2rem;
          top: rem(-81px);
          color: #333;
          font-weight: 600;
          width: 10rem;
          height: 5rem;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 20px;
          transition: all 0.4s;

          &::before {
            content: '';
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            background-color: #fff;
            top: rem(75px);
            transform: rotate(45deg);
            border-bottom: 1px solid #ccc;
          }
        }

        &:hover {
          color: #fff;
        }
        &:hover + .sub__tooltip {
          display: flex;
          justify-content: center;
          align-items: center;
          animation: tooltip 1s ease 1s;
          animation-fill-mode: backwards;

          @include respond(phone) {
            display: none;
          }
        }

        @include respond(phone) {
          & {
            font-size: 1rem;
            margin: 0.3rem;
          }
        }
      }
    }

    &:active,
    &:focus {
      outline: none;
    }

    &.front {
      // cursor: auto;
      background-image: url('../images/wb.png'),
        linear-gradient(to right, #9f2d72, #571f5c);
      background-size: contain;
      background-repeat: no-repeat;
      background-position-y: 25%;
      @include respond(phone) {
        background-image: linear-gradient(to right, #9f2d72, #571f5c);
      }
    }

    &.back {
      position: absolute;
      transform: rotateY(180deg);
      background-image: linear-gradient(to left, #9f2d72, #571f5c);

      @include respond(phone) {
        background-image: linear-gradient(to right, #9f2d72, #571f5c);

        transform: none;
        display: none;
      }
    }

    &.active {
      transform: rotateY(-180deg);
      @include respond(phone) {
        transform: none;
        border-bottom: none;
        border-radius: 1rem 1rem 0 0;
      }
    }

    &.disactive {
      transform: rotateY(0);
      @include respond(phone) {
        position: relative;
        display: inline-block;
        margin-top: -2px;
        border-top: none;
        border-radius: 0 0 1rem 1rem;
        animation: moveInDown 1s ease;
      }
    }
  }
}

#accordion {
  & .card-header {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 1.2rem;
    font-weight: 700;

    & a {
      margin: 0 auto;
    }
    &__title {
      color: #ccc;

      transition: all 0.2s ease-in;
      &:hover {
        color: #fff;
      }
    }

    & .fa {
      cursor: pointer;
      color: #ccc;
      transition: all 0.2s ease-in;
      margin-right: 2rem;

      &:hover {
        color: #fff;
      }
    }
  }
  & .card-body {
    a {
      color: #ccc;
      &:hover {
        color: #fff;
      }
    }

    & .sub {
      font-size: 1rem;

      & .fa {
        margin-right: 2rem;
      }
    }
  }
}
