.product__calendar {
  margin-bottom: 1rem;

  .calendar {
    border-radius: 0;
    background: none;
    padding: 0 !important;
  }
}

a,
a:link,
a:visited {
  text-decoration: none;
}

.table {
  position: unset;
  transition: all 0.4s;
  margin-bottom: 0;
  height: 25px;
  // width: auto;
  &__image {
    height: 40px;
    border-radius: 0.375rem;
  }

  &__container {
    border: 1px solid #ccc;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.3);
    display: none;
    // position: absolute;
    // top: -6.3rem;
    // left: 6rem;
    top: 27.2%;
    position: fixed;
    left: 47%;
    // top: 23%;
    // left: 26%;
    z-index: 100;

    &::before {
      content: '';
      position: absolute;
      left: -7px;
      top: 127px;
      width: 1rem;
      height: 1rem;
      transform: rotate(45deg);
      border: 1px solid #ccc;
      box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.3);
      background: #fff;
      display: none;
    }
  }

  &__bigImage {
    width: 23.5rem;
    position: relative;
  }

  &:hover > &__container {
    display: inline-block;
    animation: moveInLeft 1s ease;
  }

  @include respond(phone) {
    &__container {
      top: 33.2%;

      left: 24%;
    }

    &__bigImage {
      width: 14.5rem;
    }
  }
}

.table.product-images {
  width: auto;
}

.product__body {
  &-link,
  &-link:link,
  &-link:visited {
    color: $wb;
    font-weight: 600;
    text-align: center;

    & h5 {
      background-color: $wb;
      color: #fff;
      padding: 0.6rem;
      border-radius: 5px;
    }
  }

  &-description {
    font-size: 0.93rem;
    overflow: hidden;

    .field i {
      width: 23px;
      margin-right: 10px;
    }
    .overw {
      display: flex;

      .overview {
        color: #fff;
        font-weight: 600;
        border-radius: 5px;
        padding: 0.2rem 0.3rem;
      }
      .rating {
        margin-right: 0.3rem;
      }

      .feedback {
        background-color: $teal;
      }

      @include respond(phone) {
        & {
          flex-direction: column;
          font-size: 11px;

          .overview {
            padding-left: 1rem;
          }
          .rating {
            margin-right: 0;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    & .table tbody tr td {
      white-space: unset;
    }
  }

  &-gallery {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    &--img {
      cursor: pointer;
      overflow: hidden;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &:hover img {
        transform: scale(1.1);
        transition: all 0.6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      }
    }

    &--imgBig {
      width: 100%;
    }
  }
}

.product {
  display: flex;
  align-items: flex-start;

  & .panel {
    flex: 1;
  }

  & .p-0 {
    background-color: #1d2226;
  }

  &__info {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    flex: 1;

    & .tab-content {
      padding: 15px 0 !important;
    }

    .nav-tabs .nav-link.active {
      border-bottom: 2px solid #1d2226;
      transition: all 0.2s;
    }
  }

  &__stat {
    width: 50%;
    max-width: 54.5rem;
    margin-left: 1rem;
  }

  @include respond(tab-land) {
    & {
      flex-direction: column;

      & .panel {
        width: 100%;
      }
      &__info {
        width: 100%;
        max-width: 100%;
      }

      &__stat {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  @include respond(phone) {
    & {
      .calendar {
        width: 100%;
      }

      .d-flex {
        flex-direction: column;
      }
    }
  }
}

.stock__container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: '1200px') {
    & {
      flex-direction: row;
    }
  }

  @media screen and (max-width: '1000px') {
    & {
      flex-direction: column;
    }
  }
}
