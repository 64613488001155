.loader {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99;

  @include respond(tab-port) {
    left: 60%;
  }

  @media screen and (max-width: '767px') {
    left: 45%;
  }

  @include respond(phone) {
    left: 40%;
  }
}
