.scroll-to-top {
  & i {
    cursor: pointer;
    position: fixed;
    right: 1rem;
    bottom: 4rem;
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.4s ease;
    animation: moveIn 0.6s;

    &:hover {
      color: $black;
    }
  }

  .fadeout {
    animation: moveOut 0.6s;
  }
}
