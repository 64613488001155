#header {
  .buttons__group {
    display: flex;
    align-items: center;

    &-btn {
      border-radius: 25px;
      margin-right: 1rem;
      width: 10rem;
    }

    a {
      height: 100%;
      .button {
        height: 100%;
        min-width: 7rem;
        width: auto;
        position: relative;

        border: none;
        transition: all 0.4s ease-in;

        &__log {
          background-image: linear-gradient(to bottom, #0bc4c4, #019797);
        }

        &__reg {
          background-image: linear-gradient(to bottom, #0bc4c4, #019797);
        }

        span {
          color: #fff;
          font-size: 1rem;
          font-weight: 700;
          z-index: 2;
          position: relative;
        }

        &:active,
        &:focus {
          outline: none;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-image: linear-gradient(to top, #333333, #333333);
          transform: scaleX(0);
          transition: transform 0.2s, height 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
            background-color 0.1s;
        }

        &:hover::before {
          transform: scaleX(1);
          height: 100%;
        }
      }
    }
  }
}
