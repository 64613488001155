// div.table-responsive {
//   /* width */
//   &::-webkit-scrollbar {
//     width: 1rem;
//   }

//   /* Track */
//   &::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px #ccc;
//     // border-radius: 10px;
//   }

//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: rgba($shadow, 0.4);
//     border-radius: 10px;
//   }

//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: $shadow;
//   }
// }

.modal-table {
  top: 40%;
  left: 0;

  @media screen and (max-width: 768px) {
    & {
      left: 0;
    }
  }
}

.table {
  & thead {
    overflow: hidden;
    text-overflow: ellipsis;

    & tr {
      & th {
        font-weight: 600;
        border-bottom: 1px solid lighten($dark, 55%);
        background-color: #fff;

        .fa {
          transition: all 0.4s ease;
          &:hover {
            color: black;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  & thead,
  & tbody,
  & tfoot {
    & tr {
      & td,
      & th {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.with-form-control,
        &.with-input-group,
        &.with-radio,
        &.with-img,
        &.with-btn,
        &.with-btn-group,
        &.with-checkbox {
          padding-top: rem(4px);
          padding-bottom: rem(4px);
        }
        &.with-radio,
        &.with-checkbox {
          & .radio,
          & .checkbox {
            padding-top: 0;
          }
        }
      }
    }
  }

  & tbody {
    & tr {
      position: relative;
      transition: all 0.2s;

      .panel &:hover {
        background-color: #eee;
      }

      & td .table {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      // & td:first-child {
      //   position: unset;
      // }
    }
  }

  &.table-transparent {
    color: rgba($white, 0.75);
    background: none;

    & thead,
    & tbody,
    & tfoot {
      & tr {
        & th,
        & td {
          border-color: rgba($white, 0.15);
        }
      }
    }
    & thead {
      & tr {
        & th {
          border-bottom-color: rgba($white, 0.5);
        }
      }
    }
  }
  &:not(.table-bordered) {
    & thead {
      & tr {
        & th {
          border-top: none;
        }
      }
    }
  }
}

.resizer {
  display: inline-block;
  //  background: blue;
  width: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;

  //   &.isResizing {
  //     background: red;
  //   }
}

@include respond(phone) {
  .d-flex {
    flex-direction: column;
  }
}

.table__download {
  color: #fff;
  transition: all 0.2s ease-in;
  &:hover {
    color: #ccc;
  }
}

.header__title:first-child {
  display: none !important;
}

.table-responsive {
  overflow-y: auto;
  max-height: 64vh;
}
.panel-expand .table-responsive {
  max-height: 100vh;
}

.panel-title.d-flex {
  @include respond(phone) {
    & {
      flex-direction: row;
    }
  }
}

.new-tooltip__tt {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 37%;
  left: 50%;
  margin-left: -60px;
  cursor: pointer;
}

.table__header-title:hover + .new-tooltip__tt {
  visibility: visible;
}

.table__bottom {
  &-button button {
    color: #333;
    font-weight: 700;
    border-radius: 4px;

    &:disabled {
      color: #6f8293;
      font-weight: inherit;
    }

    &:not(:disabled) {
      animation: enable 0.3s linear;
    }
  }

  .numaration {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 974px) {
    & {
      flex-direction: column;
      &-button {
        margin-bottom: 10px;
      }
    }
  }
}

// th,
// td {
//   position: relative;
// }

// .tbody__cell {
//   padding: 0 !important;
// }

.report_date-calendar {
  background: #171b1f;
  color: #fff;
  border: none;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
  font-weight: bold;
}

.dark-active {
  background-color: #171b1f;
}
