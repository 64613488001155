.footer_contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  @include respond(phone) {
    & {
      flex-direction: column;
    }
  }
}
