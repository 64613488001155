// .brandList {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   align-items: center;
//   text-align: center;

//   &__container {
//     height: 6.4rem;
//     width: 8.1rem;
//     margin-bottom: 0.5rem;
//     border: 5px solid #333333;
//     border-radius: 5px;
//     outline: none;
//     cursor: pointer;
//     transition: all 0.4s;

//     &:hover {
//       transform: scale(1.1);
//     }

//     &-item {
//       display: flex;
//       flex-direction: column;

//       .item__logo {
//         flex: 0 0 3.12rem;
//       }

//       .item__name {
//         flex: 0 0 2.88rem;
//         font-weight: 700;
//         background: #333333;
//         color: #fff;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
//     }
//   }
// }

.panel > .table-responsive > .table > tbody > tr > td:first-child {
  width: 100px;
}
