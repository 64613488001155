#navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  & .buttons__group {
    cursor: pointer;
    font-weight: 700;
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & .button,
    & .button:active {
      padding: 10px 15px;
      font-weight: 600;
      border: none;
      background: inherit;
      color: #6f8293;
      transition: all 0.2s ease-in;

      &:focus {
        outline: none;
      }

      &:hover {
        color: $shadow;
      }
    }
    & .disabled {
      color: #6f8293;

      &:hover {
        color: #6f8293;
      }
    }

    & .active {
      background-color: #1d2226;
      color: #fff;
      border-radius: 5px;
      &:hover {
        color: #fff;
      }
    }
  }

  .nav {
    &-item {
      cursor: pointer;
      font-weight: 700;
      font-size: 0.9rem;

      & .active {
        background-color: #1d2226;
      }
    }
    @include respond(phone) {
      & {
        flex-direction: column;
      }
    }
  }

  .calendar {
    margin-bottom: 0;
    padding: 0;
    background-color: inherit;
    border-radius: unset;

    & .react-datepicker-popper {
      left: -50px !important;
    }

    & .form-control {
      color: #fff;
      background-color: #1d2226;
      text-align: center;
    }

    @include respond(tab-port) {
      & {
        flex-direction: column;
      }
    }
  }

  @include respond(phone) {
    & {
      justify-content: unset;
    }
  }
}

.phill__chartable {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .apexcharts-legend {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30%;
    right: 30px !important;

    @include respond(big-desktop) {
      & {
        width: 40%;
        text-overflow: unset;
      }
    }
  }

  @media screen and (max-width: '1060px') {
    & {
      flex-direction: column;
    }
  }

  @include respond(phone) {
    & .col-xl-6 {
      padding: 0;
    }
  }
}

.pill__history {
  display: flex;
  flex-direction: column;

  width: 100%;

  & .p-0 {
    background-color: #1d2226;
  }

  & .header__title:first-child {
    display: none !important;
  }

  & .footer__title:last-child {
    // display: none;
  }
}
