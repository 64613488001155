.footer {
  z-index: $footer-zindex;
  padding: rem(10px) 0;
  margin: 0 $content-padding-x 0 $content-padding-x + $sidebar-width;
  border-top: 1px solid lighten($dark, 60%);
  line-height: rem(20px);
  font-weight: 600;
  color: lighten($dark, 15%);

  // display: flex;
  // justify-content: space-between;

  @if $enable-rtl {
    margin-left: $content-padding-x;
    margin-right: $content-padding-x + $sidebar-width;
  }
  @include media-breakpoint-down(sm) {
    margin-left: $content-padding-x-sm;

    @if $enable-rtl {
      margin-right: $content-padding-x-sm;
    }
  }

  & a {
    color: lighten($dark, 15%);
    transition: all 0.2s ease-in;
    &:hover {
      color: #000000;
    }
  }

  // & > * {
  //   width: 40%;
  // }

  &__right {
    display: flex;
    justify-content: space-between;
    width: 50%;

    & .mailto {
      display: flex;
      align-items: center;
      align-self: flex-start;
    }
  }

  &__termsAndPrivacy {
    display: flex;
    justify-content: space-between;
  }

  @include respond(tab-port) {
    & {
      &__right {
        flex-direction: column;
      }

      &__termsAndPrivacy {
        flex-direction: column;
      }
    }
  }

  @include respond(phone) {
    & {
      &__right {
        width: auto;
        text-align: end;
      }
    }
  }

  &__desc {
    font-size: 13px;
    & .mailto {
      display: flex;
      align-items: center;
      margin-right: 90px;
    }

    & .youtube {
      transition: all 0.2s ease-in;
      &:hover {
        color: red;
      }
    }
    & .telegram {
      transition: all 0.2s ease-in;
      &:hover {
        color: #3ca2d9;
      }
    }

    @media screen and (max-width: '840px') {
      font-size: 11px;
    }
    @media screen and (max-width: '600px') {
      & {
        flex-direction: column;
        text-align: center;
        & > *:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        & .mailto {
          margin-right: 0;
        }
      }
    }
  }
}
