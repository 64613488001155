.widget-stats,
.widget.widget-stats {
  position: relative;
  color: $white;
  padding: rem(15px);

  @include border-radius($border-radius);

  & .stats-info {
    & h4 {
      font-size: rem(12px);
      margin: rem(5px) 0;
      color: rgba($white, 0.7);
    }
    & p {
      font-size: rem(24px);
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  & .stats-link {
    & a {
      display: block;
      margin: rem(15px) rem(-15px) rem(-15px);
      padding: rem(7px) rem(15px);
      background: rgba($black, 0.4);
      text-align: right;
      color: rgba($white, 0.7);
      text-decoration: none;
      transition: all 0.2s ease-in;

      @if $enable-rtl {
        text-align: left;
      }

      &:hover,
      &:focus {
        background: rgba($black, 0.6);
        color: $white;
      }
    }
  }
  & .stats-icon {
    font-size: rem(42px);
    height: rem(56px);
    width: rem(56px);
    text-align: center;
    line-height: rem(56px);
    color: rgba($black, 0.15);
    position: absolute;
    right: rem(15px);
    top: rem(15px);
    margin-left: rem(15px);

    @if $enable-rtl {
      right: auto;
      left: rem(15px);
    }

    &.stats-icon-lg {
      font-size: rem(128px);
      top: rem(15px);
      right: 0;
      color: $white;
      width: rem(128px);
      height: rem(50px);
      line-height: rem(50px);
      text-shadow: rem(3px) rem(7px) rgba($black, 0.25);
      opacity: 0.15;

      @if $enable-rtl {
        right: auto;
        left: 0;
      }

      & i {
        position: relative;
      }
    }
    &.stats-icon-square {
      opacity: 1;
      font-size: rem(42px);
      top: rem(15px);
      right: rem(15px);
      height: rem(56px);
      width: rem(56px);
      text-align: center;
      line-height: rem(56px);
      margin-left: rem(15px);

      @include border-radius($border-radius * 4);
      @if $enable-rtl {
        right: auto;
        left: rem(15px);
      }
    }
  }
  & .stats-content {
    position: relative;
  }
  & .stats-title {
    position: relative;
    margin: 0 0 rem(2px);
    font-size: rem(12px);
    color: rgba($white, 0.7);
  }
  & .stats-desc {
    margin: rem(-5px) 0 rem(-3px);
    color: rgba($white, 0.7);
  }
  & .stats-number {
    font-size: rem(24px);
    margin-bottom: rem(15px);
    font-weight: 600;
    letter-spacing: rem(1px);
  }
  & .stats-progress {
    background: rgba($black, 0.5);
    height: rem(2px);
    margin: 0 0 rem(15px);

    @include border-radius($border-radius);

    & .progress-bar {
      background: $white;

      @include border-radius(0 $border-radius $border-radius 0);
    }

    &.stats-progress-light {
      background: rgba($white, 0.3);
    }
  }

  &.text-inverse {
    &.stats-desc {
      letter-spacing: 0;
      font-weight: 400;
      color: rgba($black, 0.85);
    }
    &.stats-title {
      font-weight: 700;
      color: rgba($black, 0.85);
    }
    &.stats-number {
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  &.bg-white {
    & .stats-progress {
      background: rgba($black, 0.1);

      & .progress-bar {
        background: $dark;
      }
    }
  }
}
