.discounts {
  text-align: center;
  width: 100%;
  margin: 2rem auto 1rem auto;

  @include respond(phone) {
    width: 100%;
  }

  h3 {
    color: #fff;
    background: #1d2226;
    margin-bottom: 0;
    padding: 1rem;
    border-radius: 4px 4px 0 0;
  }
}

.promo {
  display: flex;
  // justify-content: center;
  align-items: center;
  margin: 0 auto 1rem auto;
  padding: 1rem;
  border-radius: 5px;
  transition: all 0.4s ease-in;

  & form {
    display: flex;

    & input {
      margin-right: 10px;
      font-weight: bold;
    }

    & button {
      color: #fff;
      transition: all 0.2s;
    }
  }

  & p {
    font-weight: bold;
    font-size: 15px;
    padding: 10px;
    margin: 0 auto;
    color: #fff;
    animation: moveIn 1s ease-out;
  }
}

.tariffs__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  flex-wrap: wrap;

  @include respond(big-desktop) {
    & {
      width: 80%;
      margin: 0 auto;
    }
  }

  .tariff {
    margin-bottom: 2rem;
    border: 2px solid;
    border-radius: 2rem;
    height: 30rem;
    width: 30%;
    max-width: 25rem;
    min-width: 18rem;
    color: $light;
    box-shadow: 0 0.5rem 2rem rgba($dark, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    transition: all 0.4s;

    &-basic {
      background-image: linear-gradient(
          to top,
          rgba($blue-lighter, 0.8),
          rgba($blue-darker, 0.8)
        ),
        url('../images/basic.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-color: $blue;
      color: #fff;

      .tariff__heading {
        & h2 {
          background-image: linear-gradient(
            to top,
            $blue-lighter,
            $blue-darker
          );
          background-clip: text;
          color: transparent;
        }
      }

      button {
        &:hover {
          background-color: $blue-darker;
        }
      }
    }
    &-advanced {
      background-image: linear-gradient(
          to top,
          rgba(#41dd41, 0.8),
          rgba(#298a29, 0.8)
        ),
        url('../images/advanced.jpg');
      background-size: cover;
      background-position: center;
      border: 2px solid #32a932;

      .tariff__heading {
        & h2 {
          background-image: linear-gradient(
            to top,
            rgba(#41dd41, 0.8),
            rgba(#298a29, 0.8)
          );
          background-clip: text;
          color: transparent;
        }
      }

      button {
        &:hover {
          background-color: #298a29;
        }
      }
    }

    &-professional {
      background-image: linear-gradient(
          to top,
          rgba($gray-600, 0.8),
          rgba($gray-800, 0.8)
        ),
        url('../images/professional.jpg');
      background-size: 200%;
      background-position: center;
      border-color: $gray-700;

      .tariff__heading {
        & h2 {
          background-image: linear-gradient(to top, $gray-600, $gray-800);
          background-clip: text;
          color: transparent;
        }
      }

      button {
        &:hover {
          background-color: $gray-800;
        }
      }
    }

    &__heading {
      padding: 0.5rem 1rem;
      width: 80%;
      margin: 3rem 0 1.5rem 0;
      background: $light;
      border-radius: 40px;

      & h2 {
        margin-bottom: 0;
        font-size: 1rem;
      }
    }

    &__description {
      flex: 1;
      align-self: center;
      padding: 0 2rem 2rem 2rem;
      overflow: hidden;
      width: 100%;

      & .price {
        position: relative;

        & .old-price {
          position: absolute;
          left: 84px;
          top: -13px;
          color: #dee2e6;
          animation: moveIn 1s ease-out;
          &::after {
            content: '';
            background-color: rgba(255, 0, 0, 0.6);
            width: 44px;
            height: 2px;
            position: absolute;
            left: -4px;
            top: 7px;
            transform: rotate(15deg);
          }

          @media screen and (min-width: 1800px) {
            & {
              left: 100px;
            }
          }
        }
      }

      & p {
        margin-bottom: 4px;
        font-size: 13px;
      }

      &-total {
        margin-top: 1rem;

        & h2 {
          position: relative;

          & .old-total {
            position: absolute;
            position: absolute;
            right: 77px;
            top: -12px;
            color: #dee2e6;
            animation: moveIn 1s ease-out;

            &::after {
              content: '';
              background-color: rgba(255, 0, 0, 0.6);
              width: 55px;
              height: 2px;
              position: absolute;
              left: -4px;
              top: 7px;
              transform: rotate(15deg);
            }

            @media screen and (min-width: 1800px) {
              & {
                right: 100px;
              }
            }
          }
        }
      }
      .fas {
        margin-left: 1rem;
      }

      &--name {
        font-size: 15px;
        margin-bottom: 1rem !important;
      }
    }

    &__booking {
      margin-bottom: 3rem;
      width: 75%;
      & button {
        background-color: inherit;
        text-transform: uppercase;
        color: #fff;
        transition: all 0.4s ease;
        width: 100%;
        border-radius: 20px;
        border-width: 5px;
      }
    }
  }

  .tariff:hover {
    // transform: translateY(-3px);
    filter: brightness(1.05);
    box-shadow: 0 1rem 2rem rgba($dark, 0.2);
    transform: translateY(-2px);
  }

  @include respond(phone) {
    & {
      flex-direction: column;

      .tariff {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.form {
  &__group {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    background: #fff;
    padding-bottom: 0.2rem;
    border-radius: 0 0 4px 4px;
    font-weight: 700;
    border: 2px solid #1d2226;
    box-shadow: 0 0.5rem 1rem rgba($dark, 0.2);
  }

  &__radio-group {
    display: flex;

    justify-content: space-between;
  }
  &__radio-group:not(:last-child) {
    margin-right: 1rem;
  }

  &__radio-input {
    display: none;
  }

  &__radio-label {
    cursor: pointer;
    position: relative;
    padding-left: 2rem;
  }

  &__radio-button {
    height: 1.5rem;
    width: 1.5rem;
    border: 5px solid $teal;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -3px;

    &::after {
      content: '';
      display: block;
      height: 0.7rem;
      width: 0.7rem;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 49%;
      transform: translate(-50%, -50%);
      background-color: $teal;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
  &__radio-input:checked ~ &__radio-label &__radio-button::after {
    opacity: 1;
  }
}

.tariff__back {
  cursor: pointer;
  color: #777;

  transition: color 0.4s;
  &:hover {
    color: $shadow;
  }
}

.billing {
  text-align: center;
}

.success {
  text-align: center;
  &__logo {
    width: 260px;
    margin: 3rem auto;
    color: $green;
    text-shadow: rem(11px) rem(7px) rem(10px) rgba(black, 0.4);
    transition: all 0.4s;
    & i {
      font-size: 20em;
    }
    &:hover {
      color: $lime;
    }
  }
}

.failed {
  text-align: center;
  &__logo {
    width: 260px;
    margin: 3rem auto;
    color: #ff0000;
    text-shadow: rem(11px) rem(7px) rem(10px) rgba(black, 0.4);
    transition: all 0.4s;
    & i {
      font-size: 20em;
    }
    &:hover {
      color: #a01010;
    }
  }
}

.pay__info {
  max-width: '500px';
  margin: 'auto';
  text-align: 'center';

  & li {
    list-style: none;
  }
}

.note-content {
  font-size: 13px;
}
