.profile-header {
  position: relative;
  overflow: hidden;

  & .profile-header-cover {
    background-image: url(../images/profile-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba($black, 0) 0%,
        rgba($black, 1) 100%
      );
    }
    &.admin {
      background-image: url(../images/admin-bg.jpg);
    }

    &.bills {
      background-image: url(../images/bills-bg.jpg);
    }
  }
  & .profile-header-content {
    color: $white;
    padding: $content-padding-x;
    position: relative;

    @include media-breakpoint-down(md) {
      padding: $content-padding-x-sm;

      @include display-flex();
      @include flex-align(center);
    }
  }
  & .profile-header-img {
    float: left;
    width: rem(120px);
    height: rem(120px);
    overflow: hidden;
    position: relative;
    z-index: 10;
    margin: rem(-10px) 0 rem(-20px) 0;
    padding: rem(3px);
    background: $white;

    @include border-radius($border-radius);
    @if $enable-rtl {
      float: right;
    }
    @include media-breakpoint-down(md) {
      margin: 0;
      width: rem(80px);
      height: rem(80px);
    }

    & + .profile-header-info {
      margin-left: (rem(120px) + $content-padding-x) - rem(10px);

      @if $enable-rtl {
        margin-left: 0;
        margin-right: (rem(120px) + $content-padding-x) - rem(10px);
      }
      @include media-breakpoint-down(md) {
        margin-left: rem(20px);

        @if $enable-rtl {
          margin-left: 0;
          margin-right: rem(20px);
        }
      }
    }
    & img {
      max-width: 100%;
    }
  }
  & .profile-header-info {
    & h4 {
      font-weight: 600;
      color: $white;
    }
  }
  & .profile-header-tab {
    position: relative;
    background: $white;
    list-style-type: none;
    margin: rem(-10px) 0 0;
    padding: 0 0 0 (rem(120px) + $content-padding-x);

    @include border-radius(0);
    @if $enable-rtl {
      padding-right: rem(120px) + $content-padding-x;
      padding-left: 0;
    }
    @include media-breakpoint-down(md) {
      padding: 0;
      margin: 0;
      overflow: scroll;

      @include display-flex();
      @include flex-wrap(nowrap);
      @if $enable-rtl {
        padding: 0;
      }
    }

    & .nav-item {
      display: inline-block;
      margin: 0;

      & .nav-link {
        display: block;
        color: lighten($dark, 30%);
        line-height: rem(20px);
        padding: rem(10px) rem(20px);
        text-decoration: none;
        font-weight: bold;
        font-size: rem(12px);
        border: none;

        @include border-radius(0);

        &:hover,
        &:focus {
          background: none;
          color: lighten($dark, 15%);
        }
        &.active {
          color: $dark;
          background: none;
        }
      }
    }
  }
}
.profile-content {
  padding: rem(25px);

  @include clearfix();

  & .tab-content,
  & .tab-pane {
    background: none;
  }
  & .table {
    &.table-profile {
      & > thead {
        & > tr {
          & > th {
            border: none;
            border-bottom: none !important;
            color: $dark;
            background: none;

            & h4 {
              font-size: rem(20px);
              margin-top: 0;

              & small {
                display: block;
                font-size: rem(12px);
                font-weight: normal;
                margin-top: rem(5px);
              }
            }
          }
        }
      }
      & tbody {
        & > tr {
          & td {
            border: none;
            color: $dark;
            background: none;

            & .form-control {
              border-color: mix($dark, $body-bg, 10%);

              &:focus {
                border-color: $input-focus-border-color;
              }
            }
            &.field {
              width: 20%;
              text-align: right;
              font-weight: 600;
              color: $dark;

              @if $enable-rtl {
                text-align: left;
              }
              @include media-breakpoint-down(md) {
                width: 35%;
              }
            }
          }
          &.highlight {
            & > td {
              border-top: 1px solid mix($dark, $body-bg, 10%);
              border-bottom: 1px solid mix($dark, $body-bg, 10%);
            }
          }
          &.divider {
            & > td {
              padding: 0 !important;
              height: rem(10px);
            }
          }
        }
      }
    }
  }
}
