.page-header {
  font-size: rem(24px);
  margin: 0 0 rem(15px);
  padding: 0;
  border: none;
  border-bottom: 1px solid #c6ced5;
  line-height: rem(32px);
  font-weight: 700;
  // background: #1d2226;
  // color: #ffffff;
  // padding: 10px 15px;
  // border-top-left-radius: 3px;
  // border-top-right-radius: 3px;

  & small {
    line-height: rem(20px);
  }
}
