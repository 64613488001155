@include keyframes(fadeIn) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.panel {
  margin-bottom: rem(20px);
  background: $white;
  border: none;
  box-shadow: 0 1rem 1.2rem rgba(0, 0, 0, 0.2); // @include box-shadow(none);
  @include border-radius($border-radius-sm);

  & > .table-bordered,
  & > .table-responsive > .table-bordered {
    border: none;
    margin-bottom: 0;

    & > thead {
      & > tr {
        & > th {
          border-top: none;

          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    & > tbody {
      & > tr {
        & > td {
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
        &:last-child {
          & > td {
            border-bottom: none;
          }
        }
      }
    }
  }
  & > .form-control {
    border-right: 0;
    border-left: 0;
    padding: rem(15px);

    @include border-radius(0);

    & + .panel-footer {
      border-top: none;
    }
  }
  & > .alert,
  & > .note {
    margin: 0;

    @include border-radius(0);
  }
  & > .table,
  & > .tab-content,
  & > .list-group {
    margin-bottom: 0;
  }
  & > .tab-content {
    @include border-radius(0 0 $border-radius-sm $border-radius-sm);
  }
  & > .list-group {
    & .list-group-item {
      border-left: none;
      border-right: none;

      &:first-child {
        border-top-width: 0px;

        @include border-top-left-radius(0);
        @include border-top-right-radius(0);
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  & > div {
    & + .list-group {
      & .list-group-item {
        &:first-child {
          border-top-width: 1px;
        }
      }
    }
  }
  & > .widget-list {
    & .widget-list-item {
      @include border-radius(0);
    }
  }
  & > .table,
  & > .table-responsive > .table {
    & > thead,
    & > tbody {
      & > tr {
        & > th,
        & > td {
          &:first-child {
            @if $enable-rtl {
              padding-right: rem(15px);
            } @else {
              padding-left: rem(15px);
            }
          }
          &:last-child {
            @if $enable-rtl {
              padding-left: rem(15px);
            } @else {
              padding-right: rem(15px);
            }
          }
        }
      }
    }
  }

  & .panel-heading {
    padding: rem(10px) rem(15px);
    border: none;

    @include display-flex();
    @include flex(1);
    @include flex-align(center);
    @include flex-wrap(nowrap);
    @include border-top-left-radius($border-radius-sm);
    @include border-top-right-radius($border-radius-sm);

    & .panel-title {
      line-height: rem(20px);
      font-size: rem(12px);
      margin-top: 0;
      margin-bottom: 0;
      color: inherit;

      @include flex(1);

      & > a {
        color: inherit;
        display: block;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
      & .pull-right {
        line-height: rem(20px);
      }
    }
    & .panel-heading-btn {
      @include display-flex();
      @include flex-align(center);

      & > a {
        & + a {
          margin-left: rem(5px);

          @if $enable-rtl {
            margin-left: 0;
            margin-right: rem(5px);
          }
        }
      }
      & .btn-icon {
        @include display-inline-flex();
        @include flex-align(center);
        @include flex-justify-content(center);
      }
    }
    & .btn-group {
      & .btn {
        margin-top: rem(-7px);
        margin-bottom: rem(-7px);

        &.btn-sm {
          margin-top: rem(-5px);
          margin-bottom: rem(-5px);
        }
        &.btn-xs {
          margin-top: rem(-1px);
          margin-bottom: rem(-1px);
        }
      }
    }
    & .label,
    & .badge {
    }
    & .progress {
      min-width: rem(120px);
    }
    & .tab-overflow {
      @include flex(1);
    }
  }
  & .panel-body {
    padding: rem(15px);

    @include clearfix();

    &.panel-form,
    &.panel-table,
    &.panel-full-width,
    &.no-padding {
      padding: 0 !important;
    }
    &.no-border {
      border: none !important;
    }
    &[class^='bg-'] {
      @include border-radius(0 0 $border-radius-sm $border-radius-sm);
    }
  }
  & .panel-toolbar {
    padding: rem(10px) rem(15px);
    background: $white;
  }
  & .panel-footer {
    background: $white;
    border-top: 1px solid $border-color;
    padding: rem(10px) rem(15px);

    @include border-bottom-right-radius($border-radius-sm);
    @include border-bottom-left-radius($border-radius-sm);
  }
  & [class^='col-'] {
    &.ui-sortable {
      min-height: 0;
    }
  }

  &.panel-default {
    & > .panel-heading {
      background: lighten($dark, 75%);
      color: lighten($dark, 15%);
    }
  }
  &.panel-inverse {
    & > .panel-heading {
      background: darken($dark, 7.5%);
      color: $white;
    }
  }
  &.panel-success {
    & > .panel-heading {
      background: darken($success, 7.5%);
      color: $white;
    }
  }
  &.panel-warning {
    & > .panel-heading {
      background: darken($warning, 7.5%);
      color: $white;
    }
  }
  &.panel-danger {
    & > .panel-heading {
      background: darken($danger, 7.5%);
      color: $white;
    }
  }
  &.panel-primary {
    & > .panel-heading {
      background: darken($blue, 7.5%);
      color: $white;
    }
  }
  &.panel-info {
    & > .panel-heading {
      background: darken($info, 7.5%);
      color: $white;
    }
  }
  &.panel-loading {
    & .panel-body {
      position: relative;
      z-index: 0;

      & .panel-loader {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $white;
        opacity: 0.9;
        z-index: 10;

        @include animation(fadeIn 0.2s);
        @include border-radius(0 0 $border-radius $border-radius);
      }
    }
  }
  &.panel-expand {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    overflow: hidden;
    z-index: $panel-expand-zindex;

    @include display-flex();
    @include flex(1);
    @include flex-direction-column();
    @include border-radius(0);

    & > .panel-body,
    & > .panel-heading,
    & > .panel-footer {
      @include border-radius(0);
    }
    & > .panel-body {
      overflow-x: scroll;

      @include flex(1);

      & > .slimScrollDiv {
        height: 100% !important;

        & > div:not(.slimScrollBar):not(.slimScrollRail) {
          height: 100% !important;
        }
      }
    }
    & > .panel-heading {
      @include flex(0);

      & .fa-expand:before {
        content: '\f066';
      }
    }
    & > .hljs-wrapper {
      max-height: 50%;
      overflow: scroll;
    }
  }
  &.panel-hover-icon {
    & .panel-heading {
      & .panel-heading-btn {
        & .btn {
          & i {
            visibility: hidden;
          }
        }
      }

      &:hover,
      &:focus {
        & .panel-heading-btn {
          & .btn {
            & i {
              visibility: visible;
            }
          }
        }
      }
    }
  }
  &.panel-no-rounded-corner {
    & .panel-body,
    & .panel-footer,
    & .panel-header {
      @include border-radius(0 !important);
    }
  }
  &.panel-with-tabs {
    & .panel-heading {
      @include flex-align(center);

      & > .nav-tabs {
        margin-top: rem(-5px);
        margin-bottom: rem(-11px);
        border-bottom: none;

        & .nav-item {
          & .nav-link {
            border: none;
            margin: 0;
          }
        }
      }
    }
  }
}
.panel-group {
  & .panel {
    margin-bottom: 0;

    @include border-radius($border-radius);

    & + .panel {
      margin-top: rem(5px);
    }
  }
}
.panel-expand {
  & .header,
  & .top-menu,
  & .sidebar,
  & .sidebar-bg {
    z-index: 0;
  }
  & .content {
    & .row {
      & [class^='col-'] {
        position: inherit;
      }
    }
    & .panel-expand {
      & .row {
        & > [class^='col-'] {
          position: relative;
        }
      }
    }
  }
  & .vertical-box {
    & .vertical-box-column {
      display: table-cell;
    }
  }
  & .page-content-full-height {
    & .content {
      @include transform(none);
    }
  }
}
