.btn {
  font-weight: 600;

  &.btn-circle,
  &.btn-rounded {
    @include border-radius(rem(200px));
  }
  &.btn-icon {
    width: rem(28px);
    height: rem(28px);
    padding: 0;
    border: none;
    line-height: rem(28px);
    text-align: center;
    font-size: rem(14px);

    &.btn-xs {
      width: rem(16px);
      height: rem(16px);
      font-size: rem(8px);
      line-height: rem(16px);
    }
    &.btn-sm {
      width: rem(22px);
      height: rem(22px);
      font-size: rem(11px);
      line-height: rem(22px);
    }
    &.btn-lg {
      width: rem(34px);
      height: rem(34px);
      font-size: rem(17px);
      line-height: rem(34px);
    }
  }
  &.btn-theme {
    padding: rem(12px) rem(30px);
    font-weight: bold;

    @include border-radius($border-radius-lg);
  }
  &.btn-xs {
    font-size: rem(12px);
    line-height: rem(18px);
    padding: rem(1px) rem(5px);
  }
  &.btn-white {
    &.btn-white-without-border {
      border-color: $white;

      &:not(.active) {
        &:hover,
        &:focus {
          border-color: lighten($dark, 75%) !important;
          background: lighten($dark, 75%) !important;
        }
      }
      &.active {
        border-color: lighten($dark, 70%) !important;
      }
    }
  }
}

.btn-default {
  @include button-variant(
    lighten($silver, 20%),
    lighten($silver, 20%),
    lighten($silver, 10%),
    lighten($silver, 10%),
    lighten($silver, 10%),
    lighten($silver, 10%)
  );
}

@each $color, $value in $theme-colors {
  @if $color == 'primary' {
    $value: $blue;
  }
  .btn-#{$color} {
    @include button-variant(
      $value,
      $value,
      darken($value, 10%),
      darken($value, 10%),
      darken($value, 10%),
      darken($value, 10%)
    );

    @if $color == 'warning' or $color == 'info' or $color == 'grey' {
      color: $white;
    }
    @if $color == 'grey' {
      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
}

@each $color, $value in $colors {
  .btn-#{$color} {
    @if $color == 'white' {
      @include button-variant(
        $value,
        $border-color,
        lighten($dark, 75%),
        $border-color,
        lighten($dark, 70%),
        $border-color
      );
    }
    @if $color != 'white' {
      @include button-variant(
        $value,
        $value,
        darken($value, 10%),
        darken($value, 10%),
        darken($value, 10%),
        darken($value, 10%)
      );
    }
    @if $color == 'orange' or $color == 'aqua' or $color == 'lime' {
      color: $white;
    }
    @if $color == 'lime' {
      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
}

.btn-group,
.input-group-prepend,
.input-group-append {
  & .btn-default {
    & + .btn-default {
      border-left-color: lighten($silver, 10%);

      @if $enable-rtl {
        border-right: 1px solid lighten($silver, 10%);

        &:not(:last-child) {
          border-left: none;
        }
      }
    }
  }
  @each $color, $value in $theme-colors {
    @if $color == 'primary' {
      $value: $blue;
    }
    & .btn-#{$color} {
      & + .btn-#{$color} {
        border-left-color: darken($value, 10%);

        @if $enable-rtl {
          border-right: 1px solid darken($value, 10%);

          &:not(:last-child) {
            border-left: none;
          }
        }
      }
    }
  }
  @each $color, $value in $colors {
    & .btn-#{$color} {
      & + .btn-#{$color} {
        @if $color == 'white' {
          border-left-color: $border-color;

          @if $enable-rtl {
            border-right: 1px solid $border-color;

            &:not(:last-child) {
              border-left: 1px solid $border-color;
            }
          }
        }
        @if $color != 'white' {
          border-left-color: darken($value, 10%);

          @if $enable-rtl {
            border-right: 1px solid darken($value, 10%);

            &:not(:last-child) {
              border-left: none;
            }
          }
        }
      }
    }
  }
}

.btn-group {
  & > .btn {
    @if $enable-rtl {
      @include border-radius(0);

      &:first-child {
        margin-left: rem(-1px);
      }
      &:first-child:last-child {
        @include border-radius($border-radius);
      }
      &:not(:first-child):not(:last-child),
      &:not(:first-child):not(:last-child):not(.dropdown-toggle) {
        @include border-radius(0);
      }
      &:not(:first-child),
      &:not(:first-child):not(.dropdown-toggle),
      &:not(:first-child).dropdown-toggle {
        @include border-top-left-radius($border-radius);
        @include border-bottom-left-radius($border-radius);
      }
      &:not(:last-child),
      &:not(:last-child):not(.dropdown-toggle) {
        @include border-top-right-radius($border-radius);
        @include border-bottom-right-radius($border-radius);
      }
      & + .btn {
        @include border-top-right-radius(0);
        @include border-bottom-right-radius(0);
      }
    }
  }
  & > .btn-group:not(:first-child) {
    margin-left: 0;
    margin-right: rem(-1px);
  }
}

.btn-new {
  &,
  &:link,
  &:visited {
    text-align: center;
    padding: 0.4375rem 0.75rem;
    font-size: 0.75rem;
    line-height: 1.5;
    font-weight: 600;
    border-radius: 6px;
    transition: all 0.4s;
    text-decoration: none;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 0.5rem 1rem rgba($dark, 0.3);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.3rem 0.8rem rgba($dark, 0.3);
  }
}

.btn__tariffs {
  background: #1d2226;
  padding: 5rem 9rem 5rem 2rem;
  color: rgba(255, 255, 255, 0.911);
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  margin: 10rem auto 0 auto;
  // border-radius: 4rem;
  clip-path: polygon(
    0% 20%,
    60% 20%,
    60% 0%,
    100% 50%,
    60% 100%,
    60% 80%,
    0% 80%
  );
  box-shadow: 0 1rem 1.2rem rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;

  &:hover {
    transform: translateX(10px);
    color: #fff;
  }
}
