.calendar {
  background-color: #1d2226;
  border-radius: 3px 3px 0 0;
  margin-bottom: -1px;
  display: flex;
  justify-content: space-between;

  @include respond(phone) {
    & {
      width: 100%;
    }
  }
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc !important;
}

.table__navigation {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-bottom: 1px solid #e4e7ea;

  & .form-horizontal {
    margin-right: auto;
  }

  & .calendar {
    background-color: inherit;
  }

  @include respond(tab-port) {
    & {
      flex-direction: column-reverse;
      align-items: unset;

      & .form-horizontal {
        margin-right: 0;
      }

      & .calendar {
        width: 100%;
        justify-content: space-around;
      }

      & label {
        flex-direction: column;
        & p {
          margin: 0 !important;
        }

        & input {
          width: 100% !important;
        }
      }
    }
  }
}
