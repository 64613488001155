.nav-item {
  cursor: pointer;
}

.tab-content {
  transition: all 0.5s;

  .active {
    opacity: 1;
  }
}

.stocks__container {
  display: flex;
  justify-content: space-between;
  transition: all 0.4s;

  & > * {
    flex: 1;
  }

  &-header {
    padding: 1rem;
    background: $shadow;
    color: #fff;

    @include respond(phone) {
      & {
        font-size: 0.6rem;
        padding: 0.3rem 0;
      }
    }
  }

  & .stocks li {
    cursor: pointer;
  }

  & .regions li {
    cursor: pointer;
  }

  & .stocks li:hover {
    background: #eeee;
  }
  & .regions li:hover {
    background: #eeee;
  }

  & ul {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    padding-left: 0;
  }

  & li {
    list-style: none;
    padding: 1rem;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    white-space: pre;
    transition: all 0.4s;

    &.active {
      background: $wb !important;
      border-color: #b60999;
      color: #fff;
    }

    &:first-child {
      margin-top: 0.8rem;
    }

    @include respond(phone) {
      & {
        font-size: 0.6rem;
        padding: 0.3rem 0;
      }
    }
  }

  & .available li {
    background: #cce3f8;
    border-color: #348fe2;
  }
  & .unavailable li {
    background: #ffd6d5;
    border-color: #ff5b57;
  }
}
