@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      //600px
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      //900px
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      //1200px
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media only screen and (max-width: 98.4em) {
      //1575px
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    //1800px+
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}
